import { CLAIM_TYPE_LAYOUT_COLORS, CLAIM_TYPE_LIGHT_LAYOUT_COLORS, DEFAULT_LAYOUT_COLOR, CURRENT_CLAIM_TYPE_LAYOUT_COLOR, CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR, KEEP_LAYOUT_COLOR, } from '@declaration/layout';
function dispatchIfDifferentColor(color, store) {
    if (store.state.layout.color === color) {
        return;
    }
    store.dispatch('layout/setColor', color);
}
export function applyLayoutColor(color, { store }) {
    if (color && typeof color !== 'symbol' && !('questionsStackBackgroundColor' in color)) {
        throw new Error('Wrong layout color implementation passed to layout color middleware "applyLayoutColor"');
    }
    if (!color) {
        return dispatchIfDifferentColor(DEFAULT_LAYOUT_COLOR, store);
    }
    if (color === KEEP_LAYOUT_COLOR) {
        // Do nothing as component will manage on its own
        return;
    }
    const typeTrigram = store.state.qualification.typeTrigram;
    if (color === CURRENT_CLAIM_TYPE_LAYOUT_COLOR) {
        return dispatchIfDifferentColor(CLAIM_TYPE_LAYOUT_COLORS[typeTrigram], store);
    }
    if (color === CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR) {
        return dispatchIfDifferentColor(CLAIM_TYPE_LIGHT_LAYOUT_COLORS[typeTrigram], store);
    }
    dispatchIfDifferentColor(color, store);
}
