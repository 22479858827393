import { ClaimTypeTrigram } from '@shared/types/api/claim';
export const COLORS = {
    grey: {
        '1': getColor('grey-1'),
        '2': getColor('grey-2'),
        '3': getColor('grey-3'),
        '4': getColor('grey-4'),
        '5': getColor('grey-5'),
        '6': getColor('grey-6'),
        '02': getColor('grey-02'),
        '03': getColor('grey-03'),
        '06': getColor('grey-06'),
        '10': getColor('grey-10'),
        '20': getColor('grey-20'),
        '30': getColor('grey-30'),
        '50': getColor('grey-50'),
        '80': getColor('grey-80'),
    },
    blue: {
        '1': getColor('blue-1'),
        '2': getColor('blue-2'),
        '3': getColor('blue-3'),
        '4': getColor('blue-4'),
        '5': getColor('blue-5'),
        '6': getColor('blue-6'),
    },
    green: {
        '1': getColor('green-1'),
        '2': getColor('green-2'),
        '3': getColor('green-3'),
        '4': getColor('green-4'),
        '5': getColor('green-5'),
    },
    red: {
        '1': getColor('red-1'),
        '2': getColor('red-2'),
        '3': getColor('red-3'),
    },
    yellow: {
        '1': getColor('yellow-1'),
        '2': getColor('yellow-2'),
        '3': getColor('yellow-3'),
        '4': getColor('yellow-4'),
        '5': getColor('yellow-5'),
        '6': getColor('yellow-6'),
        '7': getColor('yellow-7'),
    },
    black: getColor('black'),
    dark: getColor('darken'),
    white: getColor('white'),
    transparent: {
        white: {
            '0': getColor('transparent-white-0'),
            '15': getColor('transparent-white-15'),
            '40': getColor('transparent-white-40'),
            '80': getColor('transparent-white-80'),
            '100': getColor('transparent-white-100'),
        },
        black: {
            '0': getColor('transparent-black-0'),
            '40': getColor('transparent-black-40'),
            '100': getColor('transparent-black-100'),
        },
        dark: {
            '0': getColor('transparent-dark-0'),
            '50': getColor('transparent-dark-50'),
            '100': getColor('transparent-dark-100'),
        },
    },
};
export const CLAIM_TYPE_IMAGES = {
    [ClaimTypeTrigram.WaterDamage]: require(`@declaration/assets/images/claim-type/DDE.svg`),
    [ClaimTypeTrigram.Robbery]: require(`@declaration/assets/images/claim-type/VOL.svg`),
    [ClaimTypeTrigram.Drought]: require(`@declaration/assets/images/claim-type/SEC.svg`),
    [ClaimTypeTrigram.Disaster]: require(`@declaration/assets/images/claim-type/CAT.svg`),
    [ClaimTypeTrigram.StormSnowHail]: require(`@declaration/assets/images/claim-type/TNG.svg`),
    [ClaimTypeTrigram.Glass]: require(`@declaration/assets/images/claim-type/BDG.svg`),
    [ClaimTypeTrigram.Fire]: require(`@declaration/assets/images/claim-type/INC.svg`),
    [ClaimTypeTrigram.Electric]: require(`@declaration/assets/images/claim-type/ELE.svg`),
    [ClaimTypeTrigram.Vehicle]: require(`@declaration/assets/images/claim-type/VEH.svg`),
    [ClaimTypeTrigram.Other]: require(`@declaration/assets/images/claim-type/AUT.svg`),
    [ClaimTypeTrigram.Empty]: '',
};
export const DEFAULT_SPLASHSCREEN_IMAGES = {
    DDE: ['leakDetection', 'bathtub', 'remoteExpert'],
    TNG: ['blownTiles', 'brokenWindow', 'remoteExpert'],
};
export const SPLASHSCREEN_IMAGES = [
    {
        name: 'leakDetection',
        svg: require(`@declaration/assets/images/water-damage/leakDetection.svg`),
    },
    {
        name: 'bathtub',
        svg: require(`@declaration/assets/images/water-damage/bathtub.svg`),
    },
    {
        name: 'remoteExpert',
        svg: require(`@declaration/assets/images/water-damage/remoteExpert.svg`),
    },
    {
        name: 'creditCard',
        svg: require(`@declaration/assets/images/payout/creditCard.svg`),
    },
    {
        name: 'blownTiles',
        svg: require(`@declaration/assets/images/claim-type/TNG.svg`),
    },
    {
        name: 'brokenWindow',
        svg: require(`@declaration/assets/images/storm-snow-hail/TNG-argument1.svg`),
    },
];
export const KEEP_LAYOUT_COLOR = Symbol('KeepLayoutColor');
export const CURRENT_CLAIM_TYPE_LAYOUT_COLOR = Symbol('CurrentClaimTypeLayoutColor');
export const CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR = Symbol('CurrentClaimTypeLightLayoutColor');
const layoutColorKeys = [
    'fullPageLayoutBackgroundColor',
    'fullPageLayoutColor',
    'entityCardHeaderBackgroundColor',
    'entityCardHeaderColor',
    'largeSplashScreenBackgroundColor',
    'splashScreenBackgroundColor',
    'splashScreenColor',
    'questionsBackgroundColor',
    'questionsCertificationBackgroundColor',
    'questionCertificationMobileColor',
    'questionsAsideColor',
    'questionsAsideBackgroundColor',
    'questionsAsideCertificationBackgroundColor',
    'questionsStackBackgroundColor',
    'progressBarBackgroundColor',
    'progressBarIncompleteBackgroundColor',
    'progressBarDotBackgroundColor',
    'progressBarBackgroundColorMobile',
    'progressBarIncompleteBackgroundColorMobile',
    'progressBarDotBackgroundColorMobile',
    'personalPropertyModalInitialCategorySelectionBackgroundColor',
    'personalPropertyModalSearchBackgroundColor',
    'personalPropertyModalFormBackgroundColor',
    'compensationOptionsColor',
    'modalHeaderBackgroundColor',
    'modalHeaderColor',
    'modalContentBackgroundColor',
    'modalContentColor',
    'modalFooterBackgroundColor',
    'modalFooterColor',
    'headerBackgroundColor',
    'headerColor',
    'mobileHeaderBackgroundColor',
    'mobileHeaderColor',
    'layoutBackgroundColor',
    'calendarDropdownColor',
    'breakInDamageModalFormBackgroundColor',
    'checkoutColor',
    'checkoutPlaceholder',
    'background',
    'backgroundLight',
    'componentAndModalHeader',
    'text',
];
function getLayoutColors(type) {
    return layoutColorKeys.reduce((acc, key) => {
        acc[key] = getColor(`layout-${type}-${key}`);
        return acc;
    }, {});
}
function getColor(key) {
    return `var(--cia-${key})`;
}
export const DEFAULT_LAYOUT_COLOR = getLayoutColors('default');
export const WHITE_LAYOUT_COLOR = getLayoutColors('white');
export const CLAIM_TYPE_LAYOUT_COLORS = Object.fromEntries(Object.values(ClaimTypeTrigram).map((trigram) => [trigram, getLayoutColors(trigram)]));
export const CLAIM_TYPE_LIGHT_LAYOUT_COLORS = Object.fromEntries(Object.entries(CLAIM_TYPE_LAYOUT_COLORS).map(([trigram, layoutColor]) => [
    trigram,
    {
        ...layoutColor,
        background: layoutColor.backgroundLight,
        headerBackgroundColor: layoutColor.background,
    },
]));
export const QUESTIONS_ASIDE_WIDTH = 'var(--cia-aside-width)';
